import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Heading } from "theme-ui"
import Breadcrumbs from "../components/breadcrumbs"
import AnimatedBox from "../utils/motion"

const PageHero = ({ page, image }) => (
  <Box
    sx={{ position: "relative", backgroundColor: "dark", overflow: "hidden" }}
  >
    {image && (
      <AnimatedBox
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "dark",
          ".gatsby-image-wrapper": { width: "100%", height: "100%" },
          overflow: "hidden",
        }}
        initial={{ scale: 1.15, y: 20, x: -30 }}
        animate={{ scale: 1, y: 0, x: 0 }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 13,
        }}
      >
        <GatsbyImage image={image} />
      </AnimatedBox>
    )}
    <Box
      sx={{
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        backgroundColor: "dark",
        opacity: 0.3,
      }}
    ></Box>
    <Container
      sx={{
        position: "relative",
        minHeight: ["75vh", "85vh", "85vh", "85vh"],
      }}
    >
      <AnimatedBox
        initial={{ opacity: 0, y: 40, skewX: 10 }}
        animate={{ opacity: 1, y: 0, skewX: 0 }}
        transition={{
          type: "spring",
          damping: 10,
          mass: 0.75,
          stiffness: 100,
          delay: 0.3,
        }}
        sx={{ position: "absolute", bottom: [4, 7, 7, 7] }}
      >
        <Heading
          dir="invalid"
          as="h1"
          variant="h1"
          color="light"
          sx={{ hyphens: "auto" }}
        >
          {page.title}
        </Heading>
        <Breadcrumbs page={page} />
      </AnimatedBox>
    </Container>
  </Box>
)

export default PageHero
