import { useStaticQuery, graphql } from "gatsby"

export const useStories = categoryId => {
  const stories = useStaticQuery(graphql`
    query StoriesByCategoryQuery {
      allDatoCmsStory(sort: { fields: position, order: ASC }) {
        nodes {
          ...StoryDetails
          ...StoryAllSlugLocales
        }
      }
    }
  `)

  const allStories = stories.allDatoCmsStory.nodes.filter(
    story => story.category.id === categoryId
  )

  return allStories
}
