import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Grid, Link, Flex, Text } from "theme-ui"

const PartnerCollection = ({ partners }) => (
  <Grid columns={[2, 3, 4]} gap={[0]}>
    {partners.map(partner => (
      <>
        {partner.url ? (
          <Link
            href={partner.url}
            target="blank"
            rel="noopener nofollow"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "dark",
              borderRight: "1px solid #efefef",
              padding: 4,
              "&:nth-child(4n-2)": {
                borderRight: ["none", "1px solid #efefef", "1px solid #efefef"],
              },
              "&:nth-child(6n)": {
                borderRight: ["none", "none", "1px solid #efefef"],
              },
              "&:nth-child(4n)": {
                borderRight: ["none", "1px solid #efefef", "none"],
              },
              "&:nth-child(6n-3)": {
                borderRight: ["1px solid #efefef", "none", "1px solid #efefef"],
              },
            }}
          >
            <Flex sx={{ mb: 4, height: "10rem", alignItems: "center" }}>
              <GatsbyImage image={partner.image.gatsbyImageData} />
            </Flex>
            <Text sx={{ textTransform: "uppercase", textAlign: "center" }}>
              {partner.name}
            </Text>
          </Link>
        ) : (
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              color: "dark",
              borderRight: "1px solid #efefef",
              padding: 4,
              "&:nth-child(4n-2)": {
                borderRight: ["none", "1px solid #efefef", "1px solid #efefef"],
              },
              "&:nth-child(6n)": {
                borderRight: ["none", "none", "1px solid #efefef"],
              },
              "&:nth-child(4n)": {
                borderRight: ["none", "1px solid #efefef", "none"],
              },
              "&:nth-child(6n-3)": {
                borderRight: ["1px solid #efefef", "none", "1px solid #efefef"],
              },
            }}
          >
            <Flex sx={{ mb: 4, height: "10rem", alignItems: "center" }}>
              <GatsbyImage image={partner.image.gatsbyImageData} />
            </Flex>
            <Text sx={{ textTransform: "uppercase", textAlign: "center" }}>
              {partner.name}
            </Text>
          </Flex>
        )}
      </>
    ))}
  </Grid>
)

export default PartnerCollection
