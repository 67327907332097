import React, { useEffect, useState } from "react"
import { Box, Flex, Heading } from "@theme-ui/components"
import { readRemoteFile } from "react-papaparse"
import { useTable } from "react-table"
import { ArrowDown, ArrowUp } from "react-feather"
import { getColor, lighten } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui/index"

const CsvTable = ({ title, url, legend }) => {
  // console.log(url)
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  useEffect(() => {
    readRemoteFile(url, {
      download: true,
      header: true,
      complete: results => {
        const tableColumns = []
        for (const [key, value] of Object.entries(results.data[0])) {
          tableColumns.push({ Header: `${key}`, accessor: `${key}` })
        }
        setColumns(tableColumns)
        setData(results.data)
      },
    })
  }, [url])

  const tableInstance = useTable({ columns, data })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  // data should be a .csv file
  // console.log(data)
  return (
    // apply the table props
    <Box>
      <Heading dir="invalid" variant="h2" as="h2" color="dark" sx={{ mb: [2, 2, 4], mt: 0 }}>
        {title}
      </Heading>
      <Box sx={{ overflow: "auto" }}>
        <Box
          as="table"
          {...getTableProps()}
          sx={{
            verticalAlign: "top",
            width: "100%",
            fontSize: 1,
            // optional - looks better for small cell values
            // whiteSpace: "nowrap",
            borderCollapse: "collapse",
            borderSpacing: 0,
            thead: {
              tr: {
                color: `dark`,
                backgroundColor: lighten(getColor(themeUiTheme, "dark"), 0.8),
                textAlign: "left",
                borderBottom: "1px solid",
                borderColor: "dark",
              },
            },
            th: {
              paddingY: 3,
              paddingX: 3,
              textAlign: "center",
              "&:first-child": {
                textAlign: "left",
              },
            },
            td: {
              paddingY: 3,
              paddingX: 3,
              textAlign: "center",
              "&:first-child": {
                textAlign: "left",
              },
            },
            tbody: {
              tr: {
                borderBottom: "1px solid #dddddd",
                "&:nth-of-type(even)": {
                  backgroundColor: "#f3f3f3",
                },
                "&:last-of-type": {
                  borderBottom: "2px solid",
                  borderColor: "dark",
                },
              },
            },
          }}
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map(column => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map(row => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map(cell => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </Box>
      </Box>
      {legend && (
        <Box
          sx={{ mt: [2, 2, 4] }}
          dangerouslySetInnerHTML={{ __html: legend }}
        />
      )}
    </Box>
  )
}

export default CsvTable
