import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import {
  Box,
  Text,
  Grid,
  Heading,
  Flex,
  Link,
  Container,
  Select,
} from "@theme-ui/components"
import { i18nContext } from "../../context/i18nContext"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { ArrowUpRight } from "react-feather"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import loadable from "@loadable/component"

const DocumentPreview = loadable(() => import("./documentPreview"), {
  ssr: false,
})

const DocumentCollection = ({ title, documents, showPublicationDate }) => {
  const breakpoint = useBreakpointIndex()
  const [forcePage, setForcePage] = useState(0)
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
  const documentPerPage = 8
  const pageCount = Math.ceil(filteredDocuments.length / documentPerPage)
  const categorizedDocuments = [
    ...new Set(documents.filter(a => (a.category ? a.category.name : null))),
  ]
  const categories = [
    ...new Set(categorizedDocuments.map(a => a.category.name)),
  ]

  const [selectedPage, setSelectedPage] = useState(0)
  const [selectedDocuments, setSelectedDocuments] = useState(
    filteredDocuments.slice(
      selectedPage * documentPerPage,
      selectedPage * documentPerPage + documentPerPage
    )
  )

  useEffect(() => {
    setSelectedDocuments(
      filteredDocuments.slice(
        selectedPage * documentPerPage,
        selectedPage * documentPerPage + documentPerPage
      )
    )
  }, [filteredDocuments, selectedPage])

  function handleChange(data) {
    setSelectedPage(data.selected)
  }

  function handleSelectChange(category) {
    setForcePage(0)
    setSelectedPage(0)
    if (category !== "all") {
      setFilteredDocuments(
        documents.filter(obj => {
          return obj.category.name === category
        })
      )
    } else {
      setFilteredDocuments(documents)
    }
  }

  return (
    <i18nContext.Consumer>
      {t => (
        <Box>
          <Container>
            <Grid columns={[1, 1, "3fr 1fr"]} gap={[0, 0, 64]}>
              {/* <Box></Box> */}
              <Heading dir="invalid" as="h2" variant="h2" color="dark">
                {title}
              </Heading>
              {categories.length > 1 && (
                <Flex
                  sx={{ alignItems: "center", "& > div": { flex: "0 0 100%" } }}
                >
                  <Select
                    sx={{ p: 3, borderColor: "darkBackground" }}
                    onChange={e => handleSelectChange(e.target.value)}
                    defaultValue="all"
                  >
                    <option value="all">{t.allCategories}</option>
                    {categories.map(category => (
                      <option value={category}>{category}</option>
                    ))}
                  </Select>
                </Flex>
              )}
            </Grid>
          </Container>
          <Box
            sx={{
              ".pagination": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                listStyle: "none",
                li: {
                  cursor: "pointer",
                  mx: 3,
                  "&.active": {
                    a: {
                      fontWeight: "bold",
                    },
                  },
                  "a:hover": {
                    color: "dark",
                  },
                },
              },
            }}
          >
            {selectedDocuments.map(document => (
              <Flex
                sx={{
                  alignItems: ["flex-start", "center"],
                  borderTop: "1px solid",
                  borderColor: "lightGrey",
                  "&:last-child": {
                    borderBottom: "1px solid",
                    borderColor: "lightGrey",
                  },
                  minHeight: "196px",
                }}
              >
                <Box
                  sx={{
                    width: "140px",
                    minWidth: "140px",
                    backgroundColor: "lightBackground",
                    mt: [3, 0, 0, 0],
                    mb: [3, 0, 0, 0],
                  }}
                >
                  {document.documents && document.documents.length > 0 && (
                    <DocumentPreview document={document.documents[0]} />
                  )}
                </Box>
                <Container sx={{ paddingY: [4, 0] }}>
                  <Flex
                    sx={{
                      flexDirection: ["column", "column", "row"],
                      justifyContent: ["space-between"],
                      alignItems: ["flex-start", "flex-start", "center"],
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: [3, 3, 0],
                        maxWidth: ["100%", "100%", "70%"],
                      }}
                    >
                      {document.category && (
                        <Text
                          dir="invalid"
                          as="p"
                          sx={{
                            color: "primary",
                            mb: 2,
                            textTransform: "uppercase",
                            fontSize: 0,
                          }}
                        >
                          {document.category.name}
                        </Text>
                      )}
                      <Text
                        dir="invalid"
                        as="p"
                        variant="h6"
                        sx={{
                          marginTop: [0],
                          marginBottom: [0],
                          lineHeight: 1.3,
                          fontSize: [2, 3],
                        }}
                      >
                        {document.title}
                      </Text>
                      {(showPublicationDate || document.subtitle) && (
                        <Flex
                          sx={{
                            mt: 2,
                            "*": {
                              mr: 1,
                            },
                            alignItems: "center",
                          }}
                        >
                          {document.subtitle && (
                            <Text dir="invalid" as="p" variant="caption">
                              {document.subtitle}
                            </Text>
                          )}
                          {showPublicationDate && document.subtitle && (
                            <Text> - </Text>
                          )}
                          {showPublicationDate && (
                            <Text
                              as="p"
                              variant="caption"
                              color="darkBackground"
                              dir="invalid"
                            >
                              {document.meta.firstPublishedAt}
                            </Text>
                          )}
                        </Flex>
                      )}
                    </Box>
                    <Box
                      sx={{
                        "*": { mr: 4, "&:last-child": { mr: 0 } },
                      }}
                    >
                      {document.documents
                        ? document.documents.map(file => (
                            <Flex
                              sx={{
                                alignItems: "center",
                                fontSize: 2,
                              }}
                            >
                              <Flex
                                sx={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "full",
                                  width: "50px",
                                  height: "50px",
                                  minWidth: "50px",
                                  minHeight: "50px",
                                  mr: 3,
                                  backgroundColor: "primary",
                                }}
                              >
                                <ArrowUpRight
                                  color={getColor(themeUiTheme, "light")}
                                />
                              </Flex>
                              <OutboundLink
                                href={file.url}
                                target="blank"
                                rel="noopener nofollow"
                              >
                                {t.download} .{file.format}
                              </OutboundLink>
                            </Flex>
                          ))
                        : null}
                    </Box>
                  </Flex>
                </Container>
              </Flex>
            ))}
            {pageCount > 1 && (
              <Box sx={{ mb: 6 }}>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handleChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  forcePage={forcePage}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default DocumentCollection
