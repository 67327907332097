import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Box, Grid, Text, Flex } from "theme-ui"
import { useStories } from "../../hooks/useStories"
import { getStoryPath } from "../../utils/path"
import { ArrowUpRight } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui/index"
import { Link } from "../link"
import { i18nContext } from "../../context/i18nContext"

const StoriesCollection = ({ id }) => {
  const stories = useStories(id)
  const [selectedStory, setSelectedStory] = useState(0)
  return (
    <Grid columns={[1, 1, "3fr 3fr 1fr"]} gap={0}>
      <Flex
        sx={{
          backgroundColor: "dark",
          p: [4, 4, 5],
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Text
          variant="h3"
          sx={{ color: "light", fontSize: [4, 4, 5], mt: 0, hyphens: "auto" }}
        >
          {stories[selectedStory].title}
        </Text>
        <Flex
          sx={{
            alignItems: "center",
            mt: [4, 6],
            a: { color: "light" },
            fontSize: [2, 3],
          }}
        >
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "full",
              width: "50px",
              height: "50px",
              minWidth: "50px",
              minHeight: "50px",
              mr: 3,
              backgroundColor: "light",
            }}
          >
            <ArrowUpRight color={getColor(themeUiTheme, "dark")} />
          </Flex>
          <i18nContext.Consumer>
            {t => (
              <Link
                href={getStoryPath(
                  stories[selectedStory],
                  stories[selectedStory].locale
                )}
              >
                {t.discoverMore}
              </Link>
            )}
          </i18nContext.Consumer>
        </Flex>
      </Flex>
      <Box
        sx={{
          maxHeight: "32rem",
          ".gatsby-image-wrapper": {
            display: "block",
            height: "100%",
            minHeight: "16rem",
          },
        }}
      >
        <GatsbyImage image={stories[selectedStory].heroImage.article} />
      </Box>
      <Flex
        sx={{
          flexDirection: ["row", "row", "column"],
          maxHeight: "32rem",
          overflow: "auto",
          backgroundColor: "dark",
        }}
      >
        {stories.map((story, index) => (
          <Flex
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: index === selectedStory ? "secondary" : "dark",
              p: 3,
              minWidth: "10rem",
              cursor: "pointer",
              "&:hover": { backgroundColor: "secondary" },
            }}
            onClick={() => setSelectedStory(index)}
          >
            <Box
              sx={{
                borderRadius: "full",
                overflow: "hidden",
                width: "8rem",
                minWidth: "8rem",
                height: "8rem",
                minHeight: "8rem",
                ".gatsby-image-wrapper": { display: "block", height: "100%" },
              }}
            >
              <GatsbyImage image={story.heroImage.list} />
            </Box>
            <Text
              as="p"
              sx={{
                margin: 0,
                fontSize: 0,
                color: "light",
                textTransform: "uppercase",
                mt: 3,
              }}
            >
              {story.company}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Grid>
  )
}

export default StoriesCollection
