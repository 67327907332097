import React, { useState } from "react"
import ReactModal from "react-modal"
import auth from "../../services/auth"
import { Box, Button, Container, Input, Label, Text } from "theme-ui"
import { useBreakpointIndex } from "@theme-ui/match-media"

const SignInForm = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, showError] = useState(false)

  const index = useBreakpointIndex()
  ReactModal.defaultStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 5000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      width: index === 0 ? "90vw" : "644px",
      padding: "0 !important",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      maxHeight: "80vh",
    },
  }

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    showError(false)
    auth
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        // Signed in
        // ...
        // console.log(user)
        setOpen(false)
      })
      .catch(error => {
        // console.log(errorCode, errorMessage)
        // ..
        setLoading(false)
        showError(true)
      })
  }

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <Container>
        <Box
          as="form"
          method="post"
          name="sign-in-form"
          onSubmit={handleSubmit}
        >
          <Box sx={{ mb: [2, 4, 4, 4] }}>
            <Label htmlFor="email" mb={1}>
              Email
            </Label>
            <Input
              onChange={e => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              value={email}
              required
              sx={{
                padding: [3],
              }}
            />
          </Box>
          <Box sx={{ mb: [2, 4, 4, 4] }}>
            <Label htmlFor="password" mb={1}>
              Password
            </Label>
            <Input
              onChange={e => setPassword(e.target.value)}
              type="password"
              name="password"
              id="password"
              value={password}
              required
              sx={{
                padding: [3],
              }}
            />
          </Box>
          <Box>
            <Button
              as="input"
              type="submit"
              value={loading ? `Loading...` : `Log In`}
              sx={{
                padding: [3],
              }}
            />
          </Box>
          {error && <Text color="red">Invalid credentials</Text>}
        </Box>
      </Container>
    </ReactModal>
  )
}

export default SignInForm
