import React from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"

const BannerCollection = ({ banners }) => {
  const colorArray = ["dark", "primary", "darkBackground"]
  return (
    <Grid columns={[1, 1, 1, banners.length]} gap={0}>
      {banners.map((banner, index) => (
        <Flex
          sx={{
            backgroundColor: colorArray[index],
            minHeight: ["34rem"],
            position: "relative",
            p: [5],
          }}
        >
          <Flex
            sx={{
              position: "relative",
              zIndex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Text dir="invalid" variant="h2" as="h4" color="light" sx={{ marginTop: 0 }}>
              {banner.title}
            </Text>
            <RichContentStructuredText text={banner.body} theme="dark" />
          </Flex>

          {banner.image && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                ".gatsby-image-wrapper": {
                  height: "100%",
                  width: "100%",
                },
              }}
            >
              <GatsbyImage image={banner.image.gatsbyImageData} />
            </Box>
          )}
        </Flex>
      ))}
    </Grid>
  )
}

export default BannerCollection
