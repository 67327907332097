import React from "react"
import { Box, Container, Flex, Link } from "@theme-ui/components"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui/index"
import { Play } from "react-feather"

const ExternalLinkHighlight = ({ link }) => {
  function trackLink(href) {
    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer.push({
        event: "click_highlight_block",
        link_href: href,
      })
    }
  }

  return (
    <Box sx={{ backgroundColor: "secondary" }}>
      <Container>
        <Box>
          <Flex
            sx={{
              alignItems: "center",
              fontSize: 2,
            }}
          >
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "full",
                width: "50px",
                height: "50px",
                minWidth: "50px",
                minHeight: "50px",
                mr: 3,
                backgroundColor: "dark",
              }}
            >
              <Play color={getColor(themeUiTheme, "light")} />
            </Flex>
            <Link
              href={link.url}
              rel="nofollow noopener"
              target="blank"
              sx={{ color: "light" }}
              onClick={() => trackLink(link.url)}
            >
              {link.anchor}
            </Link>
          </Flex>
        </Box>
      </Container>
    </Box>
  )
}

export default ExternalLinkHighlight
