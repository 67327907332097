import React from "react"
import { Box, Grid, Heading, Text } from "@theme-ui/components"
import { i18nContext } from "../../context/i18nContext"
import RichContentStructuredText from "../richContentStructuredText"

const TitleAndBody = ({ title, body, label, subtitle }) => {
  return (
    <i18nContext.Consumer>
      {t => (
        <Grid
          columns={[1, 1, "2fr 3fr"]}
          gap={[32, 64, 128]}
          dir={t.locale === "he" ? "rtl" : "auto"}
        >
          <Box>
            {label && (
              <Text
                dir="invalid"
                as={"p"}
                variant="label"
                sx={{ color: "primary" }}
              >
                {label}
              </Text>
            )}
            <Heading
              dir="invalid"
              variant="h2"
              as="h2"
              color="dark"
              sx={{ m: 0 }}
            >
              {title}
            </Heading>
            {subtitle && (
              <Text dir="invalid" variant="h4" as="h3" sx={{ mt: 3 }}>
                {subtitle}
              </Text>
            )}
          </Box>
          <Box>
            <RichContentStructuredText text={body} />
          </Box>
        </Grid>
      )}
    </i18nContext.Consumer>
  )
}

export default TitleAndBody
