import React from "react"
import { useState } from "react"
import { Box, Button, Container, Heading, Text } from "theme-ui"
import { UserContext } from "../../context/userContext"
import auth from "../../services/auth"
import DocumentCollection from "./documentCollection"
import SignInForm from "./logInForm"

const RestrictedDocuments = ({ title, documents, showPublicationDate }) => {
  const [open, setOpen] = useState(false)
  return (
    <Box>
      <UserContext.Consumer>
        {currentUser => (
          <Box>
            {currentUser && currentUser.user ? (
              <Box>
                <Container sx={{ paddingBottom: [0, 0] }}>
                  <Text onClick={() => auth.signOut()}>Logout</Text>
                </Container>
                <DocumentCollection
                  title={title}
                  documents={documents}
                  showPublicationDate={showPublicationDate}
                />
              </Box>
            ) : (
              <Container>
                <Heading dir="invalid" as="h2" variant="h2" color="dark">
                  {title}
                </Heading>
                <Box
                  sx={{ backgroundColor: "lightBackground", padding: [2, 5] }}
                >
                  <Button onClick={() => setOpen(true)}>Login</Button>
                  <SignInForm open={open} setOpen={setOpen} />
                </Box>
              </Container>
            )}
          </Box>
        )}
      </UserContext.Consumer>
    </Box>
  )
}

export default RestrictedDocuments
