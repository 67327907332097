import React from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"
import { Link } from "../link"
import { getPagePath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"

const PageGrid = ({ pages }) => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container sx={{ pt: 0 }}>
        <Grid columns={[1, 1, 2]}>
          {pages.map(page => (
            <Box key={page.originalId}>
              <ArticleThumb page={page} />
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

const ArticleThumb = ({ page }) => {
  return (
    <Box sx={{ borderTop: "2px solid", borderColor: "primary" }}>
      <Link
        href={getPagePath(page, page.locale)}
        sx={{ textDecoration: "none" }}
      >
        <Flex sx={{ flexDirection: "column" }}>
          {page.heroImage && (
            <Box
              sx={{
                mb: 2,
                ".gatsby-image-wrapper": {
                  height: "100%",
                  width: "100%",
                },
              }}
            >
              <GatsbyImage image={page.heroImage.list} alt="" />
            </Box>
          )}
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ mb: 2 }}>
              {page.meta && (
                <Text
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="caption"
                >
                  {page.meta.firstPublishedAt}
                </Text>
              )}
            </Box>
            <Heading
              dir="invalid"
              as="h2"
              variant="h6"
              sx={{ mb: 3, mt: 0, color: "text", textTransform: "uppercase" }}
            >
              {page.title}
            </Heading>
          </Flex>
        </Flex>
      </Link>
    </Box>
  )
}

export default PageGrid
