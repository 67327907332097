import React from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import RichContentStructuredText from "../richContentStructuredText"
import CarouselNavigation from "../carouselNavigation"
SwiperCore.use([Mousewheel, A11y, Navigation])
// End swiper

const ItemCarousel = ({ items, id }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        autoHeight={true}
        navigation={{
          nextEl: `.next-${id}`,
          prevEl: `.prev-${id}`,
        }}
        watchOverflow
        grabCursor
      >
        {items.map((item, index) => (
          <SwiperSlide key={item.originalId}>
            <Item richContent={item} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          position: "absolute",
          top: [3, 4],
          right: [3, 4],
          zIndex: 1,
        }}
      >
        <CarouselNavigation id={id} />
      </Box>
    </Box>
  )
}

const Item = ({ richContent, index }) => (
  <Grid columns={[1, 1, 2]} gap={0}>
    <Flex
      sx={{
        position: "relative",
        backgroundColor: "secondary",
        alignItems: "center",
        justifyContent: "center",
        padding: [4, 5, 7],
        minHeight: ["20rem", "36rem"],
      }}
    >
      <Flex
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          alignItems: "center",
          justifyContent: "center",
          zIndex: 0,
        }}
      >
        <Text sx={{ fontSize: 9 }}>
          {index < 9 ? `0${index + 1}` : index + 1}
        </Text>
      </Flex>
      <Text
        dir="invalid"
        variant="h2"
        as="p"
        sx={{ color: "light", textAlign: "center", m: 0, zIndex: 1 }}
      >
        {richContent.title}
      </Text>
    </Flex>
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "lightBackground",
        padding: 4,
        minHeight: ["20rem", "30rem", "40rem"],
      }}
    >
      <Heading dir="invalid" variant="h3" as="p" sx={{ mt: 0 }}>
        {richContent.subtitle}
      </Heading>
      <Box>
        <RichContentStructuredText text={richContent.body} />
      </Box>
    </Flex>
  </Grid>
)

export default ItemCarousel
