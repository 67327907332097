import React from "react"
import { Box, Container, Flex, Heading, Text } from "@theme-ui/components"
import { Link } from "../link"
import { getPagePath } from "../../utils/path"
import { ArrowUpRight } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui/index"
import { i18nContext } from "../../context/i18nContext"

// Begin swiper
import SwiperCore, { Navigation, Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import CarouselNavigation from "../carouselNavigation"
import { GatsbyImage } from "gatsby-plugin-image"
import { AnimatedFlex } from "../../utils/motion"
SwiperCore.use([Mousewheel, Pagination, A11y, Navigation])
// End swiper

const PageCarousel = ({ title, pages, backgroundColor, id }) => {
  return (
    <i18nContext.Consumer>
      {t => (
        <Box sx={{ overflow: "hidden" }}>
          <Container
            variant="carousel"
            sx={{
              ".swiper-container": { overflow: "visible" },
            }}
          >
            <Flex
              sx={{
                mb: [3, 3, 5],
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
              dir={t.locale === "he" ? "rtl" : "auto"}
            >
              <Heading variant="h2" sx={{ color: "dark", mt: 0, mb: 0 }}>
                {title}
              </Heading>
              <CarouselNavigation id={id} />
            </Flex>
            <Swiper
              spaceBetween={32}
              slidesPerView={1}
              dir={t.locale === "he" ? "rtl" : "auto"}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                896: {
                  slidesPerView: 3,
                },
              }}
              navigation={{
                nextEl: `.next-${id}`,
                prevEl: `.prev-${id}`,
              }}
              watchOverflow
            >
              {pages.map(page => (
                <SwiperSlide key={page.originalId}>
                  <PageThumb page={page} backgroundColor={backgroundColor} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

const PageThumb = ({ page, backgroundColor }) => {
  const hasImage = page.pageThumb ? true : false
  // Add this function at the top of the file, after the imports

  function getContrastColor(hexColor) {
    // Remove the hash if it's there
    hexColor = hexColor.replace("#", "")

    // Convert to RGB
    const r = parseInt(hexColor.substr(0, 2), 16)
    const g = parseInt(hexColor.substr(2, 2), 16)
    const b = parseInt(hexColor.substr(4, 2), 16)

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

    // Return black for bright colors, white for dark colors
    return luminance > 0.5 ? "dark" : "light"
  }

  // ... rest of your existing code
  return (
    <Link
      href={getPagePath(page, page.locale)}
      sx={{
        textDecoration: "none",
        display: "block",
      }}
    >
      <Box
        sx={{ backgroundColor: backgroundColor || "dark" }}
        whileHover={{
          boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
          transition: { duration: 0.3 },
        }}
      >
        {hasImage && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              ".gatsby-image-wrapper": {
                height: "100%",
              },
            }}
          >
            <GatsbyImage image={page.pageThumb.gatsbyImageData} />
          </Box>
        )}
        <AnimatedFlex
          sx={{
            alignItems: "flex-end",
            height: ["22rem", "23rem", "28rem", "28rem"],
            padding: [3, 4, 4, 4],
            position: "relative",
            zIndex: 1,
          }}
          whileHover={{
            scale: 1.02,
            transition: { duration: 0.1 },
          }}
        >
          <Text
            as="p"
            variant="h4"
            sx={{
              margin: 0,
              color: hasImage ? "dark" : getContrastColor(backgroundColor),
            }}
          >
            {page.title}
          </Text>
          <Box
            sx={{
              position: "absolute",
              top: [3, 4, 4, 4],
              right: [3, 4, 4, 4],
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "full",
                width: "50px",
                height: "50px",
                backgroundColor: "light",
              }}
            >
              <ArrowUpRight color={getColor(themeUiTheme, "dark")} />
            </Box>
          </Box>
        </AnimatedFlex>
      </Box>
    </Link>
  )
}

export default PageCarousel
