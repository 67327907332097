import React, { useState } from "react"
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Image,
} from "@theme-ui/components"
import { i18nContext } from "../../context/i18nContext"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import CarouselNavigation from "../carouselNavigation"
import AnimatedBox, { AnimatedFlex } from "../../utils/motion"
SwiperCore.use([Mousewheel, Pagination, A11y])
// End swiper

const RichContentCollection = ({ title, subtitle, items, id }) => {
  return (
    <i18nContext.Consumer>
      {t => (
        <Box
          sx={{ overflow: "hidden" }}
          dir={t.locale === "he" ? "rtl" : "auto"}
        >
          <Container
            variant="carousel"
            sx={{
              ".swiper-container": { overflow: "visible" },
              ".swiper-slide": {
                height: "100%",
              },
            }}
          >
            <Flex
              sx={{
                mb: [3, 3, 5],
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <Heading
                dir="invalid"
                variant="h2"
                sx={{ color: "dark", mt: 0, mb: 0 }}
              >
                {title}
              </Heading>
              <CarouselNavigation id={id} />
            </Flex>
            {subtitle && (
              <Box sx={{ mb: [3, 3, 5] }}>
                <Text as="p" variant="h5">
                  {subtitle}
                </Text>
              </Box>
            )}

            <Swiper
              spaceBetween={16}
              grabCursor={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 3,
                },
              }}
              autoHeight={true}
              navigation={{
                nextEl: `.next-${id}`,
                prevEl: `.prev-${id}`,
              }}
              watchOverflow
            >
              {items.map(item => (
                <SwiperSlide key={item.originalId}>
                  <Item richContent={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

const textMotion = {
  rest: {
    y: 100,
    opacity: 0,
    transition: {
      duration: 0.4,
      type: "spring",
      damping: 10,
      mass: 0.75,
      stiffness: 100,
    },
  },
  hover: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      type: "spring",
      damping: 12,
      mass: 0.75,
      stiffness: 100,
    },
  },
}

const imageAnimation = {
  rest: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeInOut",
    },
  },
  hover: {
    scale: 1.2,
    opacity: 0.4,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeInOut",
    },
  },
}

const titleText = {
  rest: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      type: "spring",
      damping: 12,
      mass: 0.75,
      stiffness: 100,
    },
  },
  hover: {
    y: 200,
    opacity: 0,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeInOut",
    },
  },
}

const Item = ({ richContent }) => {
  const [tap, setTap] = useState(false)
  return (
    <AnimatedFlex
      sx={{
        flexDirection: "column",
        backgroundColor: "dark",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: [3, 5, 5, 5],
        minHeight: ["24rem", "30rem"],
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
      initial="rest"
      whileHover="hover"
      onTouchEnd={() => setTap(!tap)}
      animate={tap ? "hover" : "rest"}
    >
      {richContent.image && richContent.image.gatsbyImageData && (
        <AnimatedBox
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            ".gatsby-image-wrapper": {
              height: "100%",
              width: "100%",
            },
          }}
          variants={imageAnimation}
        >
          <GatsbyImage image={richContent.image.gatsbyImageData} />
        </AnimatedBox>
      )}

      <Box>
        {richContent.image && (
          <Box sx={{ mb: 4 }}>
            {!richContent.image.gatsbyImageData && (
              <Image src={richContent.image.url} />
            )}
          </Box>
        )}
      </Box>
      <AnimatedBox
        sx={{ width: ["100%", "100%", "80%"], position: "relative", zIndex: 1 }}
        variants={titleText}
      >
        <Box>
          <Text
            variant="h3"
            as="h4"
            sx={{ color: "light", mt: 0, mb: [0], fontSize: [4, 4, 4, 5] }}
          >
            {richContent.title}
          </Text>
          <Text
            as="p"
            sx={{
              color: "light",
              mt: 0,
              mb: 0,
              fontSize: [2, 3],
              lineHeight: 1.1,
            }}
          >
            {richContent.subtitle}
          </Text>
        </Box>
      </AnimatedBox>
      {richContent.body && (
        <AnimatedFlex
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            padding: [3, 5, 5, 5],
            alignItems: "flex-end",
          }}
          variants={textMotion}
        >
          <Box
            sx={{ position: "relative", zIndex: 1, p: { fontSize: [2, 3] } }}
          >
            <RichContentStructuredText text={richContent.body} theme="dark" />
          </Box>
        </AnimatedFlex>
      )}
    </AnimatedFlex>
  )
}

export default RichContentCollection
