import React from "react"
import { Box, Text, Grid, Heading, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { i18nContext } from "../../context/i18nContext"

const OrderedList = ({ title, subtitle, body }) => {
  return (
    <i18nContext.Consumer>
      {t => (
        <Box sx={{ backgroundColor: "dark", py: 7 }}>
          <Container>
            <Box
              sx={{
                borderBottom: "1px solid",
                borderColor: "secondary",
                pb: 6,
                mb: 6,
              }}
            >
              <Heading dir="invalid" variant="h2" color="secondary">
                {title}
              </Heading>
            </Box>
            <Grid
              columns={[1, 1, "2fr 3fr"]}
              gap={64}
              dir={t.locale === "he" ? "rtl" : "auto"}
            >
              <Box>
                <Text sx={{ color: "light" }} variant="article">
                  {subtitle}
                </Text>
              </Box>
              <Box>
                <RichContentStructuredText text={body} theme={"dark"} />
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default OrderedList
